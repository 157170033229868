<template>
<div class="view">
    <div class="join_banner">
        <div class="join_text_zone">
            <h1>业务适用承诺</h1>
            <p>WH-IP静态代理采用业务级定向筛选算法，保证IP的可用性和纯净度</p>
            <div >
                <router-link to="/login" class="join_btn" > 立即测试 →</router-link>

            </div>
        </div>
        <img src="../assets/dark_logo.jpg" alt="">
    </div>
</div>
</template>
<script setup>
</script>
<style lang="less" scoped>
@bg-color: #0b1d28;
@sec-bg-color: #38a3a5;
@main-color: #2fbf72;
@dark-main-color: #44a27a;
@soft-main-color: #0a100c;
@hight-light-color: #c7f9cc;
@text-color: #fff;
@box-color: #007f5f;

.join_text_zone{
    margin-left: -50px;
}
.join_banner{
    display: flex;
    background-color: @bg-color;
    justify-content: space-around;
    margin: 40px 0;
    padding: 40px 0;
    border-radius: 0;
    align-items: center;
    border-radius: 20px;
    border-left: 10px solid #82f0b9;
    overflow: hidden;
    height: 300px;
    h1{
        color: #00ea90;
        margin: 0 !important;
        margin-bottom: 20px !important;
    }
    img{
        display: block;
        width: 150px;
        height: 150px;
        box-shadow:0px 0px 10px 10px #2fbf726f,0px 0px 30px 30px #2fbf7267,0px 0px 100px 100px #2fbf726a,0px 0px 200px 200px #2fbf7215,0px 0px 300px 300px #2fbf7210;
        border-radius: 100%;
        box-sizing: border-box;
        padding: 15px;
        background-color: @main-color;
        // border: 5px solid rgb(0, 255, 149);
        // object-fit: cover;
    }
    .join_btn{
        background-color: #00ea90;
        display: block;
        width: 130px;
        padding: 10px 20px;
        margin-top: 40px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 900;
        text-decoration: none;
        color: #0b1d28;
        font-size: 15px;

        a{
            text-decoration: none;
            color: whitesmoke;
        }
    }
}
</style>