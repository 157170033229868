<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-content">
                <div class="our_intro">
                    <div class="brand_footer_box">
                        <div>
                            <img src="../assets/logo.png" alt="">
                            <img src="../assets/logo_text.jpg" alt="">
                        </div>


                        <p>凭借全球广泛的网络资源布局和深刻的业务洞察，WH-IP 将不断提升产品与服务质量，与合作伙伴携手拓展全球化业务。</p>
                    </div>
                    <div class="link_box"></div>
                </div>
                <div class="footer-warning">
                    WH-IP仅提供IP服务，用户使用WH-IP从事的任何行为均不代表WH-IP的意志和观点，与WH-IP的立场无关。严禁用户使用WH-IP从事任何违法犯罪行为本产品仅支持在境外环境下使用，产生的相关责任用户自负，对此WH-IP不承担任何法律责任。
                </div>
           <div class="footer-links">
                    <h3>友情链接</h3>
                    <ul>
                        <li><a href="https://www.bitbrowser.cn/?code=a4387439">比特浏览器</a></li>
                    </ul>
                </div> 
                <div class="footer-info">
                    <p>&copy; 2024 WH-IP. All rights reserved.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style scoped lang="less">
@bg-color: #0b1d28;
@sec-bg-color: #38a3a5;
@main-color: #2fbf72;
@dark-main-color: #44a27a;
@soft-main-color: #0a100c;
@hight-light-color: #c7f9cc;
@text-color: #fff;
// @box-color: #0b1c28;

.footer {
    background-color:#0b1c28 ;
    color: #ecf0f1;
    padding: 20px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.footer-content {
    // display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-links {
    // flex: 1;
    margin-right: 20px;
}

.footer-links h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 5px;
}

.footer-links ul li a {
    color: #ecf0f1;
    text-decoration: none;
}

.footer-links ul li a:hover {
    text-decoration: underline;
}

.footer-info {
    text-align: right;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.footer-info p {
    margin: 5px 0;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-info {
        text-align: center;
        margin-top: 20px;
    }
}

ul {
    // display: grid;
    // grid-template-columns: repeat(6, 1fr);
    // grid-gap: 20px;
    display: flex;
    flex-wrap: wrap;

    li {
        // background-color: #0f161c58;
        padding: 5px 0px;
        margin-left: 10px;
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        // text-align: left;
    }
}

.brand_footer_box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    h2,
    p {
        margin: 0;
        color: @main-color;
    }
    p{
        color: #999 !important;
    }

    img {
        width: 100px;
    }
}
.footer-warning{
    text-align: center;
}
</style>