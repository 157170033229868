<template>
    <div class="contact-container" ref="containerRef">
        <div class="contact-us">
            <div v-for="item in contactItems" :key="item.id" class="contact-item" @mouseenter="setHoveredItem(item.id)"
                @mouseleave="setHoveredItem(null)">
                <!-- 图标字体 -->
                <span :class="'icon iconfont ' + item.icon"></span>
                <span>{{ item.label }}</span>
            </div>
            <div v-if="hoveredItem" class="qr-code" :style="{ top: `${qrPosition.y}px` }">
                <img :src="hoveredItemQr" :alt="`${hoveredItem} QR Code`" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const containerRef = ref(null);
const hoveredItem = ref(null);
const qrPosition = ref({ y: 0 });

const contactItems = [
    //{ id: 'wechat', label: '微信客服', icon: 'icon-wechat-fill', qr: require('../assets/img/qr/wechat.jpg') },
    { id: 'telegram', label: 'TG', icon: 'icon-telegram', qr: require('../assets/img/qr/telegram.jpg')  },
    { id: 'skype', label: 'WhatsApp', icon: 'icon-whatsapp', qr: require('../assets/img/qr/whatsapp.jpg')  },
];

const hoveredItemQr = computed(() => {
    return contactItems.find(item => item.id === hoveredItem.value)?.qr;
});

const setHoveredItem = (id) => {
    hoveredItem.value = id;
};

const handleMouseMove = (e) => {
    if (containerRef.value) {
        const containerRect = containerRef.value.getBoundingClientRect();
        qrPosition.value = {
            y: e.clientY - containerRect.top - 50, // 50 is half the height of QR code
        };
    }
};

onMounted(() => {
    window.addEventListener('mousemove', handleMouseMove);
});

onUnmounted(() => {
    window.removeEventListener('mousemove', handleMouseMove);
});
</script>

<style scoped>
.contact-container {
    position: relative;
    display: inline-block;
}

.contact-us {
    width: 60px;
    background-color: #00ea90;
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

.contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;

}

.icon {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
}

span {
    font-size: 10px;
    color: #0b252c;
    text-align: center;
}

.qr-code {
    position: absolute;
    left: -130px;
    /* Position to the left of the contact list */
    /* background-color: #00ea90; */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
    pointer-events: none;
}

.qr-code img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    /* mix-blend-mode:darken; */

}
.icon{
    font-size: 30px;
}
</style>