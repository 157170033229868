import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import my_nav from './components/nav.vue'
import footer from './components/footer.vue'
import contact from './components/contact.vue'
import join_banner from './components/join_banner.vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import swiper from 'swiper';
import 'swiper/css';

import './assets/variables.less'
import VueGtag from 'vue-gtag';
 
const app = createApp(App)
const pinia = createPinia();
app.component('my_nav',my_nav)
app.component('contact',contact)
app.component('my_footer',footer)
app.component('join_banner',join_banner)
app.use(pinia).use(router)
.use(ElementPlus, {
  locale: zhCn,
}).mount('#app')
app.use(VueGtag, {
  config: { id: 'G-2RDLTCSPPX' }
}, router)

NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
//路由监听
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
//路由跳转结束
router.afterEach(() => {
  NProgress.done()
})

const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 200);
       super(callback);
     }
  }
