<template>
    <header>
        <a href="https://www.bitbrowser.cn/?code=a4387439">
            <div class="adg_banner" v-show="ad" >
            <div class="adg_closer" @click="ad=false">
                <el-icon><CircleCloseFilled /></el-icon>
            </div>
        </div>
        </a>

        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <nav class="navbar navbar-default">
                        <div class="container-fluid">

                            <div class="navbar-header">
                                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#bs-example-navbar-collapse-1">
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                                <a class="navbar-brand" href="index.html">
                                    <img src="../assets/logo.png" alt="" srcset="" width="60px">
                                    <img src="../assets/logo_text.jpg" alt="">

                                </a>
                            </div>

                            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul class="nav navbar-nav navbar-right">
                                    <li class="logo_text">
                                        <router-link to="/"> 首页</router-link>
                                    </li>
                                    <li><router-link to="/tkline"> TikTok专线网络</router-link></li>
                                    <li><router-link to="/proxy"> 静态住宅代理</router-link></li>
                                    <!-- <li><router-link to="/fnq"> F & Q</router-link></li> -->
                                    <li><router-link to="/joinus"> 成为代理</router-link></li>
                                    <li  class="login_box">
                                        <router-link v-if="!isLoggedIn" to="/login"> 登录｜注册</router-link>
                                        <router-link v-if="isLoggedIn" to="/clientarea/dashboard">个人中心</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>

</template>

<script setup>
import { defineProps,ref } from 'vue';

const props = defineProps({
    isLoggedIn: {
        type: Boolean,
        required: true
    }
});
import{CircleCloseFilled}from '@element-plus/icons-vue'

let ad = ref(true)
</script>

<style scoped lang="less">
@bg-color: #0b1d28;
@sec-bg-color: #38a3a5;
@main-color: #2fbf72;
@box-color: #007f5f;
@soft-main-color: #80ed99;
@hight-light-color: #c7f9cc;
@text-color: #fff;

header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100vw;
    padding: 0px 0;
    backdrop-filter: blur(30px);
    background: #0b1d2800;
    padding-top: 0;
    // height: 50px !important;
}

header .navbar {
    margin-bottom: 0px;
    border: 0px;
}

header .navbar-brand {
    width: 200px;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        font-weight: bolder;
        color: @main-color;
        letter-spacing: 5px;
        font-size: 25px;
        margin-top: 10px;
        font-family: 'DINCOROS', sans-serif !important;
    }

    img:first-child {
        width: 60px !important;
        margin-top: 10px;
    }
    img:nth-child(2){
        width: 100px !important;
        margin-top: 10px;

    }
}

header .navbar-default {
    background: none;
    border: 0px;

    .navbar-nav {
        padding-top: 10px;

        li a {
            // color: @text-color;
            padding: 10px 26px;
            font-size: 15px;
            font-weight: bolder;
            transition: all .2s ease-in-out;
            color: whitesmoke;
            &:focus {
                color: whitesmoke;
            }

            &:hover {
                color: #00ea90;
            }
        }
    }
}

.nav li a {
    z-index: 99;
    position: relative;
}

.container {
    width: 80vw;
    padding-bottom: 10px;
}

.login_box {
    background-color: #00ea90;
    border-radius: 50px;
    transition: all .5s ease-in-out;
    a{
        color: #0b1d28 !important;
    }
}

.login_box:hover {
    background-color: #00ea90;
    a{
        color: white !important;
    }
}
.adg_banner{
    background-image: url('../assets/img/ad.jpg');
    height: 6vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    .adg_closer{
        color: #909090cc;
        font-size: 30px;
        height: 100%;
        width: 95%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        cursor: pointer;
    }
}

</style>
